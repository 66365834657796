import { LaunchSource } from '~/analytics/AuthEvent'
import { Typography } from '~/components/shared/text/Typography'

export const useRegisterHomeSubTitle = (
  isInlinePurchase: boolean,
  launchSource: LaunchSource,
  isSupportedContextualAction: boolean
) => {
  let subTitle = ''

  if (isInlinePurchase) {
    if (launchSource === 'buy_service') {
      subTitle = 'Create a free Thatch account to complete your order.'
    } else {
      subTitle = 'Purchases are saved to ‘Your Library’ in the account you create.'
    }
  }

  if (isSupportedContextualAction) {
    if (launchSource == 'save_guide') {
      subTitle = 'Get back to saved guides in your personal library - anytime, anywhere.'
    } else if (launchSource == 'add_place') {
      subTitle = 'Organize saved recommendations in your own travel guides and plans.'
    } else if (launchSource == 'subscribe') {
      subTitle = 'Create a free Thatch account to get email updates from this creator.'
    }
  }

  if (subTitle) {
    return (
      <Typography
        variant="body2"
        mobileVariant="body1"
        mb={{ base: 64, sm: 56 }}
        maw={299}
        color="appPlaceholder.0"
        align="center"
      >
        {subTitle}
      </Typography>
    )
  }

  return null
}
